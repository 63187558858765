<template>
  <div class="page">
      <p
        style="
          text-align: center;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">苗仓地被直采中心隐私政策</span>
      </p>
      <p
        style="
          text-align: center;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">版本【V1.0.0】</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">发布时间：2022年7月1日</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">生效时间：2022年7月1日</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">更新时间：2022年7月1日</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >本政策仅适用于中山苗仓供应链管理有限公司（下称“我们”或“苗仓”）向您提供的所有产品即服务，包括小程序、移动客户端（APP）及其它端。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >苗仓非常注重保护用户(以下或称"您")的个人信息，我们深知个人信息对您的重要性，并将按照法律法规要求和业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >【特别提示】请您在使用我们提供的各项产品/服务前，仔细阅读并充分理解本《隐私政策》(重点内容我们已将字体加粗请您特别关注)并作出相应选择。一旦您使用或继续使用我们的产品/服务时，即意味着您同意我们按照本隐私政策处理您的相关信息。如对本隐私政策有任何疑问，您可以通过本隐私政策"如何联系我们”中载明的方式与我们联系。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >“苗仓地被直采中心”的产品/服务是指苗仓地被直采中心的运营方的网络及技术服务提供者通过小程序等途径向您提供的产品/服务。此外，针对某些特定的产品/服务，我们还将制定特定的隐私政策，并在向您提供这些特定的产品/服务之前加以说明。如相关特定的隐私政策与本隐私政策有不一致之处，适用该特定隐私政策。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >本政策所称的苗仓地被直采中心及其关联方是指</span
        ><span style="font-family: 微软雅黑; color: #ff0000"
          >中山苗仓供应链管理有限公司</span
        ><span style="font-family: 微软雅黑"
          >(注册地址:中山市小榄镇升平东路1号2008房第一卡</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >)及其关联公司及现有和/或未来设立的相关关联公司的单称或合称。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >请您注意，本政策不适用于您通过我们的产品/服务而接入的其他第三方产品/服务("其他第三方”，包括但不限于您的交易相对方、任何第三方网站以及第三方服务提供者)，具体规定请参照该第三方的隐私政策或类似声明。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">本政策将帮助您了解以下内容：</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >一、我们如何收集和使用您的用户信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >二、我们如何使用 Cookie 和同类技术</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >三、我们如何共享、转让、公开披露您的用户信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >四、我们如何保护您的用户信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >五、您的权利</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >六、我们如何处理儿童的个人信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >七、您的用户信息如何储存及如何在全球范围转移</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >八、本政策如何更新</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >九、如何联系我们</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们深知用户信息对您的重要性，并会尽全力保护您的用户信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的用户信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的用户信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >请在使用我们的产品（或服务）前，仔细阅读并了解本隐私政策。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >一、我们如何收集和使用您的用户信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们会出于本政策所述的以下目的，收集和使用您的个人信息:</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >(一)帮助您注册登录我们苗仓的产品或服务，在您注册成为我们的用户时，您需要至少提供手机号码以创建苗仓账号，并完善相关的网络身份识别信息(如头像、昵称及登录密码等)。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >如您选择授权使用第三方账号登录时，我们会从第三方获取您共享的账号信息(如头像、昵称、手机号等信息)与您的苗仓账号进行绑定用于快捷登录，我们会依据与第三方的约定，在符合相关法规的前提下，使用您的个人信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">(二)更好的为您提供产品\服务</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（1）您提供的信息</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >为便于向您提供产品\服务，我们有权从身份校验、完成交易、售后及客户服务、提供安全保障的多种角度，收集您向我们提供的</span
        ><span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >联系人信息(姓名、性别、电话号码)、
          地址信息、身份证件号码、银行账户或其他支付工具的账户信息</span
        ><span style="font-family: 微软雅黑"
          >，您在使用苗仓产品或服务的过程中所提交的任何文字、照片、视频各种形式的信息，也可能会包含或者关联到您的个人信息，我们将对于上述信息中涉及的个人信息予以严格保护。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们仅会出于本政策所述的业务功能，收集和使用您的用户信息，收集用户信息的目的在于向您提供产品或服务，您有权自行选择是否提供该信息，但多数情况下，如果您不提供，我们可能无法向您提供相应的服务，也无法回应您遇到的问题。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（2）您在使用服务过程中产生的信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >您使用我们提供的服务时，为了满足法律法规及提供服务的基本要求，保障您的账号安全与系统运行安全以及更好的使用我们的产品，提升经验管理效率，我们可能会收集您的以下信息:</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >A、位置信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >当您通过苗仓的产品或服务，使用物流配送服务时，我们会收集您或者您指定的收货人的</span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >姓名、手机号码、收货地址、订单信息</span
        ><span style="font-family: 微软雅黑"
          >。为了方便您快速录入收货地址，在您的同意下，我们会收集您的</span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >地理位置信息</span
        ><span style="font-family: 微软雅黑">。我们需要</span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >将收货人信息(姓名、手机号码、地址)</span
        ><span style="font-family: 微软雅黑">、</span
        ><span style="font-family: 微软雅黑; font-weight: bold">订单信息</span
        ><span style="font-family: 微软雅黑"
          >中的必要信息与我们合作的第三方伙伴(包括外第三方物流配送公司及</span
        >
        <span style="font-family: 微软雅黑"
          >技术合作伙伴)共享，以使您的订单能够正常送达，完成履约送货，当您通过系统授权开启移动设备的定位功能并使用基于位置提供的服务时，我们会使用有关技术获取您的</span
        ><span style="font-family: 微软雅黑; font-weight: bold">位置信息</span
        ><span style="font-family: 微软雅黑"
          >(准确度会有所不同)，这些技术包括IP地址、GPS以及能够提供相关信息的WLAN
          (如Wi-Fi)接入点、蓝牙和基站等。您可以在移动设备的系统中关闭定位服务停止我们对您所在位置信息的收集，但可能因此无法使用我们基于地理位置为您提供的服务，或者无法达到相关服务的预期效果。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >C、订单信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >当您准备对您购物车内的商品进行结算时，苗仓系统会生成您购买商品的订单。您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、下单时间、您应支付的货款金额及支付方式；上述所有信息构成您的“订单信息”，我们将使用您的订单信息来（包括与为您提供上述服务的第三方）进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务。我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >您在苗仓上生成的订单中，将可能包含您的身份信息、联络信息、收件信息、支付信息、发票抬头信息，这些都属于个人敏感信息，请您谨慎向他人展示或对外提供，我们也将会在展示订单信息时，在保证信息可用性的基础上尽量去标识化处理。在您向苗仓提供指定收件人的上述信息之前，您应确保您已获得该收件人的授权同意。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >B、支付与开票服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >当您在苗仓上进行支付行为时，需要使用到支付功能，您可以选择与我们合作的第三方支付机构（如微信支付、工商银行等，合称“支付机构”）所提供的支付服务进行支付。为实现支付服务的需要，我们会收集并将</span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >您的订单编号与交易金额</span
        ><span style="font-family: 微软雅黑"
          >分享给这些支付机构，以便下达支付指令并帮助您完成支付。在支付过程中，我们可能会收集您的第三方支付渠道的userID(个人敏感信息)。上述信息为我们向您提供您购买商品和/或服务所必须的信息，我们无法仅根据该信息获得您的个人财产信息，也不能识别特定自然人身份信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >若您需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票的电子邮箱，我们将会</span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >收集您的发票信息</span
        ><span style="font-family: 微软雅黑"
          >，以便为您提供开具发票的服务。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >D、日志信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >为了向您提供搜索历史、浏览历史、购物车或订单商品或服务展示服务，当您使用我们提供的产品/服务时，我们会收集您的浏览、搜索、交易、售后等信息并作为有关网络日志进行保存，其中包括您的IP地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、电信运营商、网络请求等。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >E、客服与售后服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >当您与我们的客服取得联系时，为了您的账号与系统安全，我们可能需要您先行提供</span
        ><span style="font-family: 微软雅黑; font-weight: bold">账号信息</span
        ><span style="font-family: 微软雅黑"
          >，并与您之前的个人信息相匹配以验证您的用户身份；当您需要我们提供与您订单相关的客户服务时，我们可能会查询您的相关</span
        ><span style="font-family: 微软雅黑; font-weight: bold">订单信息</span
        ><span style="font-family: 微软雅黑"
          >以便给予您适当的帮助和处理；当您需要客服协助您修改有关信息时，您可能还需要提供上述信息外的其他信息以便完成修改。为了方便与您联系或帮助您解决问题，我们可能还需要您提供姓名、手机号码、电子邮件及其他联系方式(个人敏感信息)。另外，我们还会收集您与我们的通讯信息(包括文字/图片/音视频/通话记录形式)、与您的需求相关联的其他必要信息。我们收集这些信息是为了调查事实与帮助您解决问题，如您拒绝提供上述信息，我们可能无法向您及时反馈投诉、申诉或咨询结果。在进行客服咨询时，您可能需要相应的开启相册、相机、麦克风权限来与客服沟通并提供相应材料。当然，您也可以随时通过您设备的相关功能设置开启/取消该权限。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >F、评价信息发布</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >您可以在苗仓上对产品和服务进行评价，我们将收集您发布的信息，并展示您的用户名、头像和发布内容。请注意，您发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评价时选择上传包含个人信息的图片，请您更加谨慎的考虑，是否在使用我们的服务时发布分享相关信息。您公开发布的信息中涉及未成年个人信息的，需在发布前取得所涉及未成年人的监护人的授权同意。前述情形下监护人有权通过本政策“如何联系我们”提供的联系方式联系我们，要求更正或删除涉及未成年人个人信息的内容。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >G、保障交易安全所必须的功能</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >为了保障您的账号安全、交易安全以及系统运行安全，满足法律法规和我们协议规则的相关要求，在您使用我们的产品/服务过程中，经您授权我们会获取您的设备信息，包括您使用的设备属性、连接和状态信息，包括设备型号、唯一设备标识符(如IMEI/androidID/IDFA/OPENUDID/GUID、SIM卡IMSI信息)、设备MAC地址、软件列表、电信运营商。为提高您使用我们提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会使用或整合您的个人信息以及我们合作伙伴取得您授权或者依法共享的信息，根据您的使用习惯和常用软件信息来综合判断您的账号及交易风险，包括验证身份，预防、发现、调查可能存在的欺诈、网络病毒、网络攻击的安全风险以及违反我们或关联方协议、政策或规则的行为，以保护您、其他用户、我们或关联方的合法权益，并记录一些我们认为有风险的链接(“URL”)。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >H、间接获取</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们的专业安全团队对个人信息会进行安全防护(包括敏感信息分级分类、敏感信息使用的访问控制、敏感信息加密存储)。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >经您授权后我们可能会从第三方获取您的个人信息，例如我们会通过您选择的支付/结算/提现直接涉及的支付机构等收集与商家服务相关的身份、支付/结算等信息，或者将您的信息共享给上述服务提供者，以验证身份并保证相关服务的顺利完成，或者更好地预防诈骗、刷单等恶意行为。</span
        ><span style="font-family: 微软雅黑"
          >我们会依据法律法规的要求以及与第三方的约定，</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >经您授权后向苗仓的关联方、合作伙伴等获得您的有关信息，并对其信息来源的合法性进行确认后使用您的这些信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >请注意，无法与任何特定个人直接建立联系的数据，不属于个人信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold">I、存储</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >为了便于您通过选择相册里的图片进行签收订单,在对产品和服务进行评价时上传图片以及保存发票文件时，我们会申请读取手机存储权限。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >J、读取手机状态和身份权限</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >为了识别用户身份，保证用户账号安全，我们会申请发现已知账户、读取手机状态和身份权限。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >您理解并同意，我们提供的产品/服务可能需要您在您的设备中开启您的位置信息、相机、相册、麦克风的访问权限，以实现这些权限所涉及信息的收集和使用。</span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >上述权限，均不会默认开启。</span
        ><span style="font-family: 微软雅黑"
          >我们会在您使用相关服务时弹窗提示您是否要开启相应权限。您可在您的设备设置查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。</span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。</span
        ><span style="font-family: 微软雅黑"
          >但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们保证会依法对收集后的用户信息进行去标识化或匿名化处理，对于无法单独或者与其他信息结合识别自然人个人身份的信息，不属于法律意义上的个人信息。</span
        ><span style="font-family: 微软雅黑">&#xa0;</span
        ><span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >如果我们将非个人信息与其他信息结合识别到您的个人身份时，或者与您的个人信息结合使用时，我们会在结合使用期间，将此类信息作为您的个人信息按照本隐私政策对其进行处理和保护。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >为了更好运营和改善我们的技术和服务，或出于商业策略的变化，当我们提供的产品或服务所需的用户信息收集、使用、处理超出上述范围或者要将收集到的用户信息用于本隐私政策未载明的其他用途，或当我们要将基于特定目的收集而来的信息用于其他目的时，我们会在获取用户信息后的合理期限内或处理用户信息前通知您，并获得您的授权同意。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（三）征得授权同意的例外</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >请您理解，根据法律法规及相关国家标准，以下情形中，我们收集和使用您的用户信息无需征得您的授权同意：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >1、与国家安全、国防安全直接相关的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >2、与公共安全、公共卫生、重大公共利益直接相关的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >5、所收集的您的用户信息是您自行向社会公众公开的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >6、从合法公开披露的信息中收集的您的用户信息，如合法的新闻报道、政府信息公开等渠道；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >7、根据您的要求签订或履行合同所必需的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >8、用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >9、 个人信息控制者为新闻单位且其在开展合法的新闻报道所必需的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">11、法律法规规定的其他情形。</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >二、我们如何使用 Cookie 和同类技术</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（一）Cookie</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie
          的小数据文件。Cookie
          通常包含标识符、站点名称以及一些号码和字符。借助于
          Cookie，网站能够存储您的访问偏好数据。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们不会将 Cookie
          用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
          Cookie。您可以清除计算机上保存的所有
          Cookie，大部分网络浏览器都设有阻止Cookie
          的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（二）网站信标和像素标签</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >除 Cookie
          外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（三）Do Not Track（请勿追踪）</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do Not
          Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了
          Do Not Track，那么我们的所有网站都会尊重您的选择。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >三、我们如何共享、转让、公开披露您的用户信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（一）共享</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们不会与其他的任何公司、组织和个人分享您的用户信息，但以下情况除外：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的用户信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">3、</span
        ><span style="font-family: 微软雅黑">&#xa0;</span
        ><span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >与我们的关联公司共享：您的用户信息可能会与我们的关联公司共享。我们只会共享必要的用户信息，且受本隐私政策中所声明目的的约束。关联公司如要改变用户信息的处理目的，将再次征求您的授权同意。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >4、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些用户信息，以提供更好的客户服务和用户体验。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。</span
        ><span style="font-family: 微软雅黑">&#xa0;</span
        ><span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >为了更好运营和改善技术和服务，您同意我们和授权合作伙伴在符合相关法律法规的前提下可将收集的信息用于其他服务和用途。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >以下列举了具体的授权合作伙伴，并提供了该第三方的隐私政策链接，我们建议您阅读该第三方的隐私政策：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们可能接入或链接至第三方提供的服务（包括网站或其他服务形式）。包括：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">1、</span
        ><span style="font-family: 微软雅黑">&#xa0;</span
        ><span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >您可利用“分享”键将苗仓中的某些内容分享到第三方或将某些内容分享到苗仓。</span
        ><span style="font-family: 微软雅黑">&#xa0;</span
        ><span style="font-family: 微软雅黑"
          >或者，或您可利用第三方服务登录苗仓。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的设备内装置COOKIES，从而正常运行上述功能；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >2、我们通过广告或我们服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">3、其他接入第三方服务的情形。</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">例如，</span
        ><span style="font-family: 微软雅黑">&#xa0;</span
        ><span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >为实现本《隐私政策》中声明的目的，我们可能会接入第三方服务商提供的SDK或其他类似的应用程序（如以嵌入代码、插件等形式），以便提供更好的客户服务和用户体验。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >目前，我们接入的第三方服务商主要包括以下几种类型：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >（1）用于进行数据统计分析；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >（2）用于进行推送相关苗木资讯及消息；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >（3）用于帮助用户在应用内完成支付；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >（4）用于在您同意的情况下获取您的位置信息；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >（5）用于便于您使用第三方账户进行登录及分享苗仓内容至第三方平台</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们接入的部分第三方SDK或类似应用程序可能会收集您的个人信息，如您在我们的服务中使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息。</span
        ><span style="font-family: 微软雅黑">&#xa0;</span
        ><span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >我们会评估这类第三方服务收集个人信息的合法性、正当性、必要性，要求该等第三方对您的个人信息采取保护措施，并严格遵守相关法律法规与监管要求。</span
        ><span style="font-family: 微软雅黑">&#xa0;</span
        ><span style="font-family: 微软雅黑"
          >我们主要接入的第三方SDK基本情况包括：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（1）小米推送SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：推送相关苗木资讯及消息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：IMEI /OAID（适用于Android
          Q以上系统）、IMEI、MD5/MEIDMD5、Android
          ID、VAID、AAID，以及您的MID（适用于MIUI系统）。您使用的设备型号、网络类型、设备存储、设备运行进程信息、应用版本、SDK版本、消息发送结果信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：提供客户端的消息推送</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://dev.mi.com/console/小程序service/push.html</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（2）华为推送SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：推送相关苗木资讯及消息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：收集您的ID、应用ID、应用包名、服务器公网IP地址，回执证书和回执地址URL信息，AAID(应用匿名标识)、应用TOKEN、Topic订阅关系，消息下发记录、Token申请记录、显示/点击/关闭打点上报记录，HMS
          Core openId</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：提供客户端的消息推送</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://developer.huawei.com/consumer/cn/service</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">/hms/pushservice.html</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（3）VIVO推送SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：推送相关苗木资讯及消息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：收集您的ID、应用ID、应用包名、服务器公网IP地址，回执证书和回执地址URL信息，AAID(应用匿名标识)、应用TOKEN、Topic订阅关系，消息下发记录、Token申请记录、显示/点击/关闭打点上报记录，HMS
          Core openId</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：提供客户端的消息推送</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://dev.vivo.com.cn/documentcenter/doc/233</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（4）OPPO推送SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：推送相关苗木资讯及消息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：收集您的ID、应用ID、应用包名、服务器公网IP地址，回执证书和回执地址URL信息，AAID(应用匿名标识)、应用TOKEN、Topic订阅关系，消息下发记录、Token申请记录、显示/点击/关闭打点上报记录，HMS
          Core openId</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：提供客户端的消息推送</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://open.oppomobile.com/wiki/doctid=10196</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（5）魅族推送SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：推送相关苗木资讯及消息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：收集您的ID、应用ID、应用包名、服务器公网IP地址，回执证书和回执地址URL信息，AAID(应用匿名标识)、应用TOKEN、Topic订阅关系，消息下发记录、Token申请记录、显示/点击/关闭打点上报记录，HMS
          Core openId</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：提供客户端的消息推送</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://open.flyme.cn/open-web/views/push.html</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（6）极光推送SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：推送相关苗木资讯及消息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：收集您的ID、应用ID、应用包名、服务器公网IP地址，回执证书和回执地址URL信息，AAID(应用匿名标识)、应用TOKEN、Topic订阅关系，消息下发记录、Token申请记录、显示/点击/关闭打点上报记录，HMS
          Core openId</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：提供客户端的消息推送</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://www.jiguang.cn/license/privacy</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（7）微信SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：便于您使用第三方账号进行登录以及分享苗木信息到第三方平台</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：外部存储、外部存储装载状态、SN码、MAC地址、Android_id</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：帮助用户在应用内使用“微信分享”功能，来实现将所选内容分享至微信的服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://open.weixin.qq.com/</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（8）微博SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：便于您使用第三方账号进行登录以及分享苗木信息到第三方平台</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：外部存储、外部存储装载状态、SN码、MAC地址、Android_id</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：帮助用户在应用内使用“微博分享”功能，来实现将所选内容分享至微博的服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：设备信息</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://open.weibo.com/development/mobile</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（9）QQSDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：便于您使用第三方账号进行登录以及分享苗木信息到第三方平台</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：外部存储、外部存储装载状态、SN码、MAC地址、Android_id</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：帮助用户在应用内使用“QQ分享”功能，来实现将所选内容分享至QQ的服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://open.tencent.com/</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（10）微信支付SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：帮助用户在应用内使用微信支付</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：设备信息</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：帮助用户在应用内使用微信支付</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用范围：电商支付</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://pay.weixin.qq.com/</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（11）支付宝SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：帮助用户在应用内使用支付宝支付</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：设备信息</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：帮助用户在应用内使用支付宝支付</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用范围：电商支付</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://docs.open.alipay.com/</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（12）高德定位SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：确认您所在的位置信息，以便您在搜索苗木时，根据您的位置优先推荐附近的苗木</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：设备信息以及在您开启位置权限的情况下收集您的位置信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：提供地图及定位相关服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：采集计算地理位置GPS等信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://lbs.amap.com/</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（13）数盟SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：苗仓数据统计分析</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：设备信息</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用方式：苗仓数据统计分析</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：数据统计，获取设备唯一标识 oaid</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://www.shuzilm.cn/main/privacy.html</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（14）QuestMobileSDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：苗仓数据统计分析</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：设备信息</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用方式：苗仓数据统计分析</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：数据统计，获取设备唯一标识 oaid</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://www.questmobile.com.cn/privacy</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（15）HiCarSDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：手机的应用和服务延展到汽车，创造智慧出行体验</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：设备信息以及在您开启位置权限的情况下收集您的位置信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：手机的应用和服务延展到汽车，创造智慧出行体验</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：设备信息以及在您开启位置权限的情况下收集您的位置信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://developer.huawei.com/consumer/cn/HiCar</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（16）易盾SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：手机号一键登录</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：设备信息、手机号码</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：向用户提供手机号一键登录功能</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://dun.163.com/clause/privacy</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（17）电信SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：手机号一键登录</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：设备信息、手机号码</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：向用户提供手机号一键登录功能</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：http://id.189.cn/banner/unPassword</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（18）联通SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：手机号一键登录</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：设备信息、手机号码</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：向用户提供手机号一键登录功能</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://saas.wostore.cn/solution.html?param=1&amp;num=3</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（19）移动SDK</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：手机号一键登录</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：设备信息、手机号码</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：向用户提供手机号一键登录功能</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：http://dev.10086.cn/numIdentific</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（20）七鱼SDK：</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：智能客户服务</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：音视频</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用方式：在线客服功能</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：设备信息（包括Mac、Android
          ID、IMEI、设备型号、操作系统版本、设备设置、设备环境）、联网相关信息并请求获取相册/相机权限、麦克风权限、外部存储权限、获取当前正在运行进程、安装应用列表、Wi-Fi信息（SSID、IP地址等）</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">官网链接：https://qiyukf.com/</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（21）TinkerBuglySDK；腾讯BuglySDK：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：及时更新bug</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：存储权限</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用方式：及时更新bug</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：崩溃日志、唯一设备识别码</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://bugly.qq.com/v2/</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（22）网易SDK；网易IMSDK；网易云信SDK：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：即时聊天</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：音视频</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用方式：在线客服功能</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：设备信息（包括Mac、Android
          ID、IMEI、设备型号、操作系统版本、设备设置、设备环境）、联网相关信息并请求获取相册/相机权限、麦克风权限、外部存储权限、获取当前正在运行进程、安装应用列表、Wi-Fi信息（SSID、IP地址等）</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：http://yunxin.163.com/</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（23）阿里SDK：</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：提供一键登录</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">数据类型：设备信息、手机号码</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：向用户提供手机号一键登录功能</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：存储权限、读取设备信息、读取网络状态、读取WIFI状态</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://www.aliyun.com/tg/aliware/8096478.html</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >以上第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。</span
        ><span style="font-family: 微软雅黑">&#xa0;</span
        ><span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >如您发现这些第三方社交媒体或其他服务存在风险时，建议您终止相关操作以保护您的合法权益并及时与我们取得联系。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（24）友盟SDK：</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：用于统计、分析数据，提供基础反作息弊能力、设备应用性能监控服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：唯一设备识别码（IMEI/Mac/android ID/OPENUDID/GUID、SIM卡
          IMSI 信息、OAID、google
          广告id）、安装应用列表、SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、iOS广告标识符（IDFA)、硬件序列号
          ESN、设备型号、传感器参数、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（Wi-Fi等）、硬盘、CPU和电池使用情况等、获取当前正在运行的进程。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：数据统计、提供 PUSH 推送服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：用于统计、分析数据，提供基础反作息弊能力、设备应用性能监控服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://developer.umeng.com/docs</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（25）百度SDK：</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：应用异常信息收集</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用目的：用于统计、分析数据，提供基础反作息弊能力、设备应用性能监控服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：唯一设备识别码（IMEI/Mac/android ID/OPENUDID/GUID、SIM卡
          IMSI 信息、OAID、google
          广告id）、安装应用列表、SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、iOS广告标识符（IDFA)、硬件序列号
          ESN、设备型号、传感器参数、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（Wi-Fi等）、硬盘、CPU和电池使用情况等、获取当前正在运行的进程。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用方式：数据统计、提供 PUSH 推送服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >使用范围：用于统计、分析数据，提供基础反作息弊能力、设备应用性能监控服务</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://dxp.baidu.com</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（26）百度人脸实名认证SDK：</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用目的：活体校验及身份核验</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >数据类型：人脸图片、身份证图片</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用方式：SDK直接采集</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">使用范围：用于实名认证</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >官网链接：https://ai.baidu.com/ai-doc/REFERENCE/Ckdym0tc9</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（二）转让</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >1、在获取明确同意的情况下转让：</span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >获得您的明确同意后</span
        ><span style="font-family: 微软雅黑"
          >，我们会向其他方转让您的用户信息；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">2、</span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >在涉及合并、收购或破产清算时</span
        ><span style="font-family: 微软雅黑"
          >，如涉及到用户信息转让，我们会再要求新的持有您用户信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（三）公开披露</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们仅会在以下情况下，公开披露您的用户信息：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          ><span style="font-family: 微软雅黑"><span>1、</span></span></span
        ><span style="font-family: 微软雅黑">获得您的主</span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >动选择或获得您的明确同意</span
        ><span style="font-family: 微软雅黑">后；</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          ><span style="font-family: 微软雅黑"><span>2、</span></span></span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >如果我们确定您出现违反法律法规或严重违反苗仓相关协议、规则的情况，或为保护苗仓及其关联公司用户或公众的人身财产安全免遭侵害以及维护良好的公平交易环境</span
        ><span style="font-family: 微软雅黑"
          >，我们可能依据法律法规或苗仓相关协议、规则披露您的相关个人信息，例如若您销售不符合安全标准的商品而严重违反苗仓规则时，我们可能会公开披露您的店铺主体信息与相关处罚情况。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          ><span style="font-family: 微软雅黑"><span>3、</span></span></span
        ><span style="font-family: 微软雅黑; font-weight: bold"
          >基于法律的披露</span
        ><span style="font-family: 微软雅黑"
          >：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的用户信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（四）共享、转让、公开披露信息时事先征得授权同意的例外</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >请您理解，根据法律法规及相关国家标准，以下情形中，我们共享、转让、公开披露您的用户信息无需征得您的授权同意：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >1、与国家安全、国防安全直接相关的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >2、与公共安全、公共卫生、重大公共利益直接相关的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >5、您自行向社会公众公开的信息；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >6、从合法公开披露的信息中收集的，如合法的新闻报道、政府信息公开等渠道。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >四、我们如何保护您的用户信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（一）我们已使用符合业界标准的安全防护措施保护您提供的用户信息，防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的用户信息。例如，在您的浏览器与“服务”之间交换数据时受
          SSL
          加密保护；我们同时对网站提供https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（二）我们会采取一切合理可行的措施，确保未收集无关的用户信息。我们只会在达成本政策所述目的所需的期限内保留您的用户信息，除非需要延长保留期或受到法律的允许。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送用户信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（四）我们将定期更新并公开安全风险、用户信息安全影响评估等报告的有关内容。您可通过以下方式获得：苗仓资讯栏目或苗仓微信公众号。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（五）互联网环境并非百分之百安全，我们将尽力确保您发送给我们的任何信息的安全性。即使我们做出了很大努力，采取了一切合理且必要的措施，仍然有可能无法杜绝您的用户信息被非法访问、被非法盗取，被非法篡改或毁坏，导致您的合法权益受损，请您理解信息网络的上述风险并自愿承担。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（六）在不幸发生用户信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报用户信息安全事件的处置情况。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >五、您的权利</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的用户信息行使以下权利：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >（一）访问您的用户信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >您有权访问您的用户信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：苗仓地被直采中心采购端小程序-“我的”入口，点击头像进入个人信息页面，以访问您的昵称、性别、联系电话。您可以在该小程序查询最近一年的订单以及支付记录、开票记录、收货地址信息、开票抬头信息。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >如果您无法通过上述链接访问这些用户信息，您可以随时使用我们的
          小程序申请售后表单或者个人中心在线客服联系，或致电400-838-6166。我们将在30天内回复您的访问请求。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >对于您在使用我们的产品或服务过程中产生的其他用户信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请致电400-838-6166。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >（二）更正您的用户信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >当您发现我们处理的关于您的用户信息有错误时，您有权要求我们作出更正。您可以通过“（一）访问您的用户信息”中罗列的方式提出更正申请。
          如果您无法通过上述链接更正这些用户信息，您可以随时使用我们的小程序订单详情页-申请售后的表单或者我的-在线客服联系，或致电400-838-6166。我们将在30天内回复您的更正请求。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >（三）改变您授权同意的范围</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >每个业务功能需要一些基本的用户信息才能得以完成（见本政策“第一部分”）。对于用户信息的收集和使用，您可以随时给予或收回您的授权同意。您可以通过在苗仓地被直采中心的采购端小程序通过相应页面提示解除微信绑定、电话绑定或者在系统中关闭设备权限功能等方式改变同意范围或撤回您的授权，或致电400-838-6166。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >当您收回同意后，我们将不再处理相应的用户信息。同时也请您注意，您撤销授权同意可能会导致某些后果，例如我们可能无法继续为您提供相应的服务或特定的功能，但您收回同意的决定，不会影响此前基于您的授权而开展的用户信息处理。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >（四）删除您的用户信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >在以下情形中，您可以向我们提出删除用户信息的请求：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >1、如果我们处理用户信息的行为违反法律法规；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >2、如果我们收集、使用您的用户信息，却未征得您的同意；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >3、如果我们处理用户信息的行为违反了与您的约定；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >4、如果您不再使用我们的产品或服务，或您注销了账号；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >5、如果我们不再为您提供产品或服务。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们将会根据您的删除请求进行评估，若满足相应规定，我们将会采取相应步骤进行处理。当您向我们提出删除请求时，我们可能会要求您进行身份验证，以保障账户的安全。当您从我们的服务中删除信息后，因为适用的法律和安全技术，我们可能不会立即从备份系统中删除相应的信息，我们将安全存储您的信息直到备份可以清除或实现匿名化。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >（五）用户信息主体注销账户</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >您随时可注销此前注册的账户，您可以通过以下方式自行操作直接申请注销账号：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >1、通过苗仓移动端APP访问“个人中心-设置-账号与安全-注销账号”</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">2、客服热线：400-838-6166</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">3、在线客服“消息-苗仓客服”</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >您可以通过上述方式与苗仓取得联系，并请根据客服热线电话的提示进行相关操作。为了维护您和其他用户的合法利益，在您注销您的苗仓用户账号前，请充分阅读、理解并同意如下内容。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >您知晓并确认，您所申请注销的苗仓用户账号：</span
        >
      </p>
      <p
        style="
          margin-left: 21pt;
          text-indent: -21pt;
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          ><span style="font-family: 微软雅黑"
            ><span style="font-family: Wingdings"></span></span
          ><span
            style="
              width: 13.8pt;
              font: 7pt 'Times New Roman';
              display: inline-block;
            "
            >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span></span
        ><span style="font-family: 微软雅黑"
          >应当不存在未了结的权利义务、或任何其他因为注销该苗仓用户账号会产生纠纷或争议的情况；</span
        >
      </p>
      <p
        style="
          margin-left: 21pt;
          text-indent: -21pt;
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          ><span style="font-family: 微软雅黑"
            ><span style="font-family: Wingdings"></span></span
          ><span
            style="
              width: 13.8pt;
              font: 7pt 'Times New Roman';
              display: inline-block;
            "
            >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span></span
        ><span style="font-family: 微软雅黑"
          >不存在任何未完结的交易或订单；</span
        >
      </p>
      <p
        style="
          margin-left: 21pt;
          text-indent: -21pt;
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          ><span style="font-family: 微软雅黑"
            ><span style="font-family: Wingdings"></span></span
          ><span
            style="
              width: 13.8pt;
              font: 7pt 'Times New Roman';
              display: inline-block;
            "
            >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span></span
        ><span style="font-family: 微软雅黑"
          >账号下的资产或虚拟权益已结清，包括但不限于任何苗币、优惠券等任何与苗仓用户账号关联的卡券等，或您自行选择放弃所有财产权益。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >如您选择注销苗仓用户账号，将导致苗仓终止为您提供服务，苗仓与您之间的相关权利义务也随即终止（依本协议其他条款另行约定不得终止的或依其性质不能终止的除外），且您将无法使用任何苗仓平台的产品与服务，同时还可能产生如下结果：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >注销账号后以下信息将被清空且无法找回：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（1）注销后苗仓产品/服务无法使用此账号，您账号下的各项信息将被清空且无法恢复（黑名单除外）；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（2）身份、账号信息、个人认证等信息将被清空；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（3）您的交易订单、支付记录、开票记录、求购记录、苗币充值消费账单将无法查看；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（4）您获得的苗币、优惠券、以及苗仓VIP会员、实地认证、看苗保障等服务或权益将视为自动放弃；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（5）您浏览、收藏、预约的苗木信息将无法查看；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（6）您的苗友圈动态将无法查看，电话、消息联系苗友等记录将被清空。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >在接收到您的请求后，我们承诺将在2个工作日内完成删除个人信息及注销用户账号的操作，人工处理更正、删除、注销用户您的帐号不超过15个工作日完成和处理。您知悉并理解，注销帐号的行为是不可逆的行为，当为您注销帐号后，我们将删除有关您的相关信息或进行匿名化处理，但法律法规及相关国家标准另有规定的除外。这也将可能导致您失去对您账户中数据的访问权，请您谨慎操作。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >当您符合约定的账户注销条件并注销苗仓账户后，您该账户内的所有信息将被清空，我们将不会再收集、使用或对外提供与该账户相关的个人信息，但您在使用苗仓服务期间提供或产生的信息我们仍需按照监管要求的时间进行保存，且在该保存的时间内依法配合有权机关的查询。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >（六）用户信息主体获取用户信息副本</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >您有权获取您的用户信息副本，您可以通过以下方式自行操作：使用我们的
          小程序反馈表单联系，或致电400-838-6166。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的用户信息副本传输给您指定的第三方。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（七）约束信息系统自动决策</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">（八）响应您的上述请求</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们将在30天内作出答复。如您不满意，还可以通过以下途径投诉：苗仓小程序-个人中心-反馈功能，或致电400-838-6166。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。也请您理解，出于安全保障的考虑、相关法律法规的要求或技术上的限制，对于您的某些请求我们可能无法做出响应，例如以下情形：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >1、与用户信息控制者履行法律法规规定的义务相关的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >2、与国家安全、国防安全直接相关的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >3、与公共安全、公共卫生、重大公共利益直接相关的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >4、与犯罪侦查、起诉、审判和执行判决等直接相关的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >5、用户信息控制者有充分证据表明用户信息主体存在主观恶意或滥用权利的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >6、出于维护用户信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >7、响应用户信息主体的请求将导致用户信息主体或其他个人、组织的合法权益受到严重损害的；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑">8、涉及商业秘密的。</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >六、我们如何处理儿童的个人信息</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们非常重视儿童个人信息的保护，我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不应创建自己的用户账户。尽管当地法律和习俗对儿童的定义不同，但我们将不满
          14 周岁的任何人均视为儿童。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >对于经父母或监护人同意而收集儿童用户信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下储存、使用或公开披露此信息，否则我们会设法尽快删除相关数据。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >鉴于现有技术和业务模式的限制，我们很难主动识别儿童的个人信息，如果您发现我们在不知情的情况下或在未事先获得可证实的监护人同意的情况下收集了儿童的个人信息，您可以及时联系我们，我们将在发现后设法及时删除，如果我们自己发现前述情形的，我们也会及时删除，法律要求我们保留的除外。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >七、您的用户信息如何储存以及如何在全球范围转移</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >原则上，我们在中华人民共和国境内收集和产生的用户信息，将存储在中华人民共和国境内。我们只会在本政策所述目的和用途所需的期限内和法律法规规定的最短期限内保留您的用户信息，超出上述保留期间后，我们会根据适用法律法规的要求删除您的用户信息或匿名化处理。法律法规另有规定，或出于公共利益、科学历史研究等的目的，或您的另行授权同意的除外，我们可能需要较长时间保留相关数据。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的用户信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的用户信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移用户信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >八、本政策如何更新</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            font-weight: bold;
            text-decoration: underline;
          "
          >我们的隐私政策可能变更。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >对于重大变更，我们还会提供更为显著的通知。本政策所指的重大变更包括但不限于：</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >1、我们的服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >3、用户信息共享、转让或公开披露的主要对象发生变化；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >4、您参与用户信息处理方面的权利及其行使方式发生重大变化；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >5、我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >6、用户信息安全影响评估报告表明存在高风险时。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >我们还会将本政策的旧版本存档，供您查阅。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold"
          >九、如何联系我们</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：苗仓小程序应用内申请售后表单及个人中心的在线客服，或致电400-838-6166。</span
        >
      </p>
      <p
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-size: 12pt;
        "
      >
        <span style="font-family: 微软雅黑"
          >一般情况下，我们将在30天内回复。</span
        >
      </p>
      <p style="line-height: 150%; font-size: 12pt">
        <span style="font-family: 微软雅黑;">&#xa0;</span>
      </p>
  </div>
</template>

<script>
export default {
  name: 'Privacy'
}
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  word-break: break-all;
}
</style>